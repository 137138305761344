<template>
<div class="inner">
    <div class="page_title">
        <div class="container">About us</div>
    </div>
    <div class="container">
        <div style="padding: 20px;" class="article" v-html="content" />
    </div>
</div>
</template>
<script>
import { getAboutus } from '@/apis/home/aboutus'
export default {
    data(){
        return {
            content: []
        }
    },
    methods: {
        async getAboutusHandler(){
            const res = await getAboutus()
            res.data.content=res.data.content.replace(/&nbsp;/ig, " ");
            this.content = res.data.content
        }
    },
    created(){
        this.getAboutusHandler()
    }
}
</script>
<style lang="stylus" scoped>
.inner
    height 100% !important
    overflow auto
    overflow-x hidden
.box
    margin-bottom 20px
.icon
    width 120px
    display block
    margin 10px auto
.heading
    font-size 18px
    margin-bottom 10px
.sub_heading
    font-size 14px
    color #abb1bf
.flex_column
    justify-content center
.container::deep
    overflow auto
</style>
<style lang="stylus">
.article span
    width 100%
    display block
    word-break break-word
    word-wrap break-word
</style>